<template>
    <v-container :fill-height="loading" class="pt-0">
        <v-container v-if="loading">
            <LoadingAnimation/>
        </v-container>

        <div v-if="license.callSign">
            <v-container class="mt-3 px-0">
                <v-row>
                    <v-col cols="12" :md="showHistory ? 8 : 12">
                        <v-card class="elevation-5">
                            <v-toolbar :color="license.licenseStatus === 'A' ? 'success' : 'error darken-1'" dark dense>
                                <h3 class="display-1 font-weight-light mx-auto">{{ license.callSign }}</h3>
                            </v-toolbar>

                            <v-list class="py-0">
                                <v-container class="px-0">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" lg="6" class="ma-0 py-0">
                                            <v-list-item two-line>
                                                <v-list-item-avatar class="my-0">
                                                    <v-icon>mdi-card-account-details-star</v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="pa-0">
                                                    <v-list-item-title>{{ license.callSign }}</v-list-item-title>
                                                    <v-list-item-subtitle>Callsign</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" class="ma-0 py-0">
                                            <v-list-item two-line>
                                                <v-list-item-avatar class="my-0">
                                                    <v-icon>{{
                                                            lookupLicenseStatusIcon(license.licenseStatus)
                                                        }}
                                                    </v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="pa-0">
                                                    <v-list-item-title>
                                                        {{ lookupLicenseStatus(license.licenseStatus) }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" class="ma-0 py-0">
                                            <v-list-item two-line>
                                                <v-list-item-avatar class="my-0">
                                                    <v-icon>mdi-gavel</v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="pa-0">
                                                    <v-list-item-title>
                                                        {{ $moment.utc(license.grantDate).format('M/D/YYYY') }}
                                                        <small>{{ license.grantDate | fromNow }}</small>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>Grant Date</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" class="ma-0 py-0">
                                            <v-list-item two-line>
                                                <v-list-item-avatar class="my-0">
                                                    <v-icon>mdi-calendar</v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="pa-0">
                                                    <v-list-item-title>
                                                        {{ $moment.utc(license.expiredDate).format('M/D/YYYY') }}
                                                        <small>{{ license.expiredDate | fromNow }}</small>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>Expires</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" class="ma-0 py-0">
                                            <v-list-item two-line>
                                                <v-list-item-avatar class="my-0">
                                                    <v-icon>mdi-numeric</v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="pa-0">
                                                    <v-list-item-title>{{ license.frn }}</v-list-item-title>
                                                    <v-list-item-subtitle>FRN</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6" class="ma-0 py-0">
                                            <v-list-item two-line :to="'/user/' + licenseUser.Username"
                                                         v-if="licenseUser">
                                                <v-list-item-avatar
                                                    :color="hideAvatarText ? 'rfaccent2' : 'rfaccent2'">
                                                    <v-img
                                                        eager
                                                        aspect-ratio="1"
                                                        :src="'https://files.mygmrs.com/avatar/' + licenseUser.Username.toLowerCase()"
                                                        :alt="licenseUser.Username + ' Avatar'"
                                                        @load="hideAvatarText = true"
                                                        @error="hideAvatarText = false"
                                                        v-show="hideAvatarText"
                                                    ></v-img>
                                                    <span
                                                        class="white--text avatar-text text-uppercase font-weight-light"
                                                        v-if="!hideAvatarText">{{
                                                            licenseUser.Callsign | splitCallsign
                                                        }}</span>
                                                </v-list-item-avatar>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{ licenseUser.Username }}</v-list-item-title>
                                                    <v-list-item-subtitle>Username</v-list-item-subtitle>
                                                </v-list-item-content>

                                                <v-list-item-icon>
                                                    <v-icon small>mdi-open-in-new</v-icon>
                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-list>

                            <v-divider></v-divider>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" xl="6"
                                           class="flex-grow-1 flex-shrink-0 text-uppercase title font-weight-light">
                                        {{ license.entityName }}
                                        <span v-if="license.streetAddress && $_.trim(license.streetAddress).length > 0"><br>{{
                                                license.streetAddress
                                            }}</span>
                                        <span v-if="license.poBox"><br>PO Box {{ license.poBox }}</span>
                                        <br>{{ license.city }}, {{ license.state }} {{ license.zipCode }}<span
                                        v-if="license.zipCodePlus4 && license.zipCodePlus4 !== '0000'">-{{
                                            license.zipCodePlus4
                                        }}</span>
                                        <span v-if="license.attentionLine"><br>ATTN: {{ license.attentionLine }}</span>

                                        <div v-if="showMap" class="text-center">
                                            <v-btn
                                                text
                                                @click="copyAddress(license)"
                                                class="mt-5"
                                            >
                                                <v-icon class="mr-1">mdi-content-copy</v-icon>
                                                Copy<span v-if="$vuetify.breakpoint.mdAndUp"> Address</span>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        xl="6"
                                        class="flex-grow-0 flex-shrink-1"
                                        align="right"
                                        :xalign="$vuetify.breakpoint.xlAndUp ? 'right' : 'center'"
                                        v-if="showMap"
                                    >
                                        <v-img
                                            :src="`https://api.mapbox.com/styles/v1/mapbox/${($vuetify.theme.dark ? 'satellite-streets-v10' : 'satellite-streets-v10')}/static/pin-s+049BEB(${license.location.lng},${license.location.lat})/${license.location.lng},${license.location.lat},5/300x300@2x?access_token=${config.MAPBOX_ACCESS_TOKEN}`"
                                            cover
                                            width="300"
                                            height="300"
                                        />
                                    </v-col>
                                    <v-col cols="12" xl="6" class="flex-grow-0 flxex-shrink-1" v-else>
                                        <v-expansion-panels>
                                            <v-expansion-panel>
                                                <v-expansion-panel-header>
                                                    <h3 class="font-weight-light">
                                                        <v-icon class="mt-n1 mr-1" color="info" size="24">
                                                            mdi-help-circle
                                                        </v-icon>
                                                        Why is my address listed?
                                                    </h3>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <p class="subtitle-1">The address listed on your FCC license is <a
                                                        :href="'https://wireless2.fcc.gov/UlsApp/UlsSearch/license.jsp?licKey=' + license.uniqueSystemIdentifier"
                                                        target="_blank">public information</a> and visible to anyone who
                                                        knows your callsign.</p>

                                                    <p class="subtitle-1">If you wish to hide this address, we recommend
                                                        getting a <a href="https://www.usps.com/manage/po-boxes.htm"
                                                                     target="_blank">PO Box</a> and <a
                                                            href="https://wireless2.fcc.gov/UlsEntry/licManager/login.jsp"
                                                            target="_blank">updating your GMRS license with the FCC</a>.
                                                    </p>

                                                    <p class="subtitle-1">We will automatically update the address once
                                                        the
                                                        FCC publishes the change, typically in 24-48 hours.</p>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    text
                                    color="white"
                                    :href="'https://wireless2.fcc.gov/UlsApp/UlsSearch/license.jsp?licKey=' + license.uniqueSystemIdentifier"
                                    target="_blank"
                                >
                                    <v-icon class="mr-1">mdi-file-certificate</v-icon>
                                    FCC License
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="success"
                                    text
                                    :href="'https://maps.google.com/maps?f=q&hl=en&geocode=&q=' + encodeURIComponent(licenseFormatted)"
                                    target="_blank"
                                >
                                    <v-icon>mdi-map-marker</v-icon>
                                    View Map
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" v-if="showHistory">
                        <v-card class="elevation-5">
                            <v-toolbar color="rfaccent2" dark dense>
                                <v-icon class="mr-2">mdi-history</v-icon>
                                <h3 class="font-weight-light">License History</h3>
                            </v-toolbar>
                            <v-timeline dense>
                                <v-timeline-item
                                    v-for="(history, i) in license.history"
                                    :key="i"
                                    :icon="historyCodes[history.code].icon"
                                    :color="historyCodes[history.code].color"
                                    fill-dot
                                    small
                                    class="mr-2"
                                >
                                    <template v-slot:opposite>
                                    <span :class="`headline font-weight-bold ${historyCodes[history.code].color}--text`"
                                          v-text="$moment.utc(history.logDate).format('YYYY')"></span>
                                    </template>
                                    <div class="py-1">
                                        <h2 :class="`title font-weight-light mb-1 ${historyCodes[history.code].color}--text`">
                                            {{ historyCodes[history.code].text }}</h2>
                                        <div class="caption text-uppercase font-weight-regular">
                                            <v-icon small class="mr-1">mdi-calendar</v-icon>
                                            {{ $moment.utc(history.logDate).format('dddd, MMMM D, YYYY') }} ({{
                                                history.logDate |
                                                    fromNow
                                            }})
                                        </div>
                                    </div>
                                </v-timeline-item>
                            </v-timeline>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-row v-else-if="error.state">
            <v-col cols="12">
                <v-alert type="error" class="mt-5" border="left" elevation="5" prominent>
                    {{ error.message }}
                </v-alert>
            </v-col>
        </v-row>

        <div v-else-if="!loading">
            <v-alert type="warning" prominent border="left" class="mt-5">
                A license was not found for <strong>{{ callsign }}</strong>.
            </v-alert>
        </div>
    </v-container>
</template>

<script>
    import LoadingAnimation from '@/components/LoadingAnimation.vue';
    import axios from 'axios';
    import moment from 'moment';
    import config from '../config';
    import md5 from 'md5';

    export default {
        name: 'License',

        components: {
            LoadingAnimation
        },

        props: ['callsign', 'showMap', 'showHistory', 'user'],

        data: () => ({
            config: config,
            loading: false,
            userLoading: false,
            tables: {
                history: {
                    headers: [
                        {text: 'Code', value: 'code'},
                        {text: 'Date', value: 'logDate'},
                    ],
                    options: {sortBy: 'logDate'}
                }
            },
            error: {
                state: false,
                message: null
            },
            license: {
                history: [],
                user: {},
                uniqueSystemIdentifier: null,
                callSign: null,
                entityName: null,
                firstName: null,
                mi: null,
                lastName: null,
                suffix: null,
                streetAddress: null,
                city: null,
                state: null,
                zipCode: null,
                zipCodePlus4: null,
                poBox: null,
                attentionLine: null,
                frn: null,
                applicantTypeCode: null,
                applicantTypeOther: null,
                updatedAt: null,
                createdAt: null,
                licenseStatus: null,
                serviceCode: null,
                grantDate: null,
                expiredDate: null,
                cancellationDate: null,
                effectiveDate: null,
                lastActionDate: null,
                id: null
            },
            licenseFormatted: null,
            historyCodes: {
                LETRES: {
                    text: 'Renewal Reminder Letter Sent',
                    color: 'info',
                    icon: 'mdi-email'
                },
                LITIN: {
                    text: 'License TIN Added',
                    color: 'success',
                    icon: 'mdi-plus'
                },
                LIEXP: {
                    text: 'License Status Set to Expired',
                    color: 'error',
                    icon: 'mdi-clock-outline'
                },
                LIREN: {
                    text: 'License Renewed',
                    color: 'success',
                    icon: 'mdi-refresh'
                },
                COR: {
                    text: 'Internal Correction Applied',
                    color: 'info',
                    icon: 'mdi-pencil'
                },
                LICAN: {
                    text: 'License Canceled',
                    color: 'error',
                    icon: 'mdi-close'
                },
                LIMOD: {
                    text: 'License Modified',
                    color: 'info',
                    icon: 'mdi-pencil'
                },
                LIAUA: {
                    text: 'Administrative Update Applied',
                    color: 'info',
                    icon: 'mdi-pencil'
                },
                PLRRPR: {
                    text: 'Paperless Renewal Reminder Letter',
                    color: 'info',
                    icon: 'mdi-email'
                },
                LTSFRN: {
                    text: 'FRN Association Letter sent',
                    color: 'info',
                    icon: 'mdi-email'
                },
                RCDUP: {
                    text: 'Reference Copy Duplicate Requested',
                    color: 'info',
                    icon: 'mdi-content-copy'
                },
                AUTHPR: {
                    text: 'Authorization Printed',
                    color: 'info',
                    icon: 'mdi-printer'
                },
                LETREG: {
                    text: 'Generate Renewal Reminder Letter',
                    color: 'info',
                    icon: 'mdi-printer'
                },
                LTSFNR: {
                    text: 'FRN Re-association Letter sent',
                    color: 'info',
                    icon: 'mdi-email'
                },
                INTDUP: {
                    text: 'Internal Duplicate Requested',
                    color: 'info',
                    icon: 'mdi-content-copy'
                },
                ESURNW: {
                    text: 'Public Safety Renewal email sent: ULS email',
                    color: 'info',
                    icon: 'mdi-email'
                },
                ESCFRN: {
                    text: 'FRN Association email sent: CORES email',
                    color: 'info',
                    icon: 'mdi-email'
                },
                LTSFND: {
                    text: 'FRN Disassociation Letter sent',
                    color: 'info',
                    icon: 'mdi-email'
                },
                ESCFNR: {
                    text: 'FRN Re-association email sent: CORES email',
                    color: 'info',
                    icon: 'mdi-email'
                },
                ESUFNR: {
                    text: 'FRN Re-association email sent: ULS email',
                    color: 'info',
                    icon: 'mdi-email'
                },
                ESUFRN: {
                    text: 'FRN Association email sent: ULS email',
                    color: 'info',
                    icon: 'mdi-email'
                },
                ESUFND: {
                    text: 'FRN Disassociation email sent: ULS email',
                    color: 'info',
                    icon: 'mdi-email'
                },
                EFUFRN: {
                    text: 'FRN Association email failed: ULS email',
                    color: 'error',
                    icon: 'mdi-email'
                },
                EFUFND: {
                    text: 'FRN Disassociation email failed: ULS email',
                    color: 'error',
                    icon: 'mdi-email'
                },
                ESCFND: {
                    text: 'FRN Disassociation email sent: CORES email',
                    color: 'info',
                    icon: 'mdi-email'
                },
                LITERM: {
                    text: 'License Terminated',
                    color: 'error',
                    icon: 'mdi-close'
                },
                EFCFRN: {
                    text: 'FRN Association email failed: CORES email',
                    color: 'error',
                    icon: 'mdi-email'
                },
                EFCFND: {
                    text: 'FRN Disassociation email failed: CORES email',
                    color: 'error',
                    icon: 'mdi-email'
                },
                LIISS: {
                    text: 'License Issued',
                    color: 'success',
                    icon: 'mdi-check'
                },
                CORADD: {
                    text: 'Internal Correction - License Added',
                    color: 'info',
                    icon: 'mdi-email'
                },
                LETLTS: {
                    text: 'License Termination Letter Sent',
                    color: 'error',
                    icon: 'mdi-email'
                },
                EFUFNR: {
                    text: 'FRN Re-association email failed: ULS email',
                    color: 'error',
                    icon: 'mdi-email'
                },
                EFCFNR: {
                    text: 'FRN Re-association email failed: CORES email',
                    color: 'error',
                    icon: 'mdi-email'
                },
                LICARE: {
                    text: 'License Cancellation Reversed',
                    color: 'success',
                    icon: 'mdi-undo'
                },
                EFURNW: {
                    text: 'Public Safety Renewal email failed: ULS email',
                    color: 'error',
                    icon: 'mdi-email'
                }
            },
            licenseUser: null,
            hideAvatarText: true
        }),
        filters: {
            fromNow(date) {
                return moment.utc(date).fromNow();
            },

            splitCallsign(str) {
                const regexNew = /^[A-Z]{4}\d{3}$/i;
                const offset = regexNew.test(str) ? 4 : 3;
                return str.substring(0, offset) + '\n' + str.substring(offset);
            }
        },
        methods: {
            md5(str) {
                if (!str) return '';
                return md5(str);
            },

            copyAddress(license) {
                let address = '';
                address += license.entityName;
                if (license.streetAddress) address += '\r\n' + license.streetAddress;
                if (license.poBox) address += '\r\nPO Box ' + license.poBox;
                address += '\r\n' + license.city + ', ' + license.state + ' ' + license.zipCode
                if (license.zipCodePlus4 && license.zipCodePlus4 !== '0000') address += '-' + license.zipCodePlus4;
                if (license.attentionLine) address += '\r\nATTN: ' + license.attentionLine;

                function handler(event) {
                    event.clipboardData.setData('text/plain', address.toUpperCase());
                    event.preventDefault();
                    document.removeEventListener('copy', handler, true);
                }

                document.addEventListener('copy', handler, true);
                document.execCommand('copy');
            },

            lookupLicenseStatus(code) {
                switch (code) {
                    case 'A':
                        return 'Active';
                    case 'E':
                        return 'Expired';
                    case 'C':
                        return 'Cancelled';
                    case 'T':
                        return 'Terminated';
                    default:
                        return 'Unknown';
                }
            },

            lookupLicenseStatusIcon(code) {
                switch (code) {
                    case 'A':
                        return 'mdi-check-circle';
                    case 'E':
                        return 'mdi-clock-alert-outline';
                    case 'C':
                        return 'mdi-close-circle';
                    case 'T':
                        return 'mdi-shield-alert';
                    default:
                        return 'mdi-help';
                }
            },

            fetchLicense() {
                this.loading = true;

                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(`${config.API_LOCATION}/license/${this.callsign}?geocode=${this.showMap ? 'true' : 'false'}`, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.error.state = false;
                        if (response.data) {
                            this.license = response.data;
                            this.license.history = this.$_.sortBy(this.license.history, 'logDate').reverse();

                            this.licenseFormatted = '';
                            if (this.license.streetAddress && this.$_.trim(this.license.streetAddress).length > 0) this.licenseFormatted += `${this.license.streetAddress}, `;
                            // if (this.license.poBox) this.licenseFormatted += `PO Box ${this.license.poBox}, `;
                            if (this.license.poBox) this.licenseFormatted += `United States Postal Service, `;
                            this.licenseFormatted += `${this.license.city}, ${this.license.state} ${this.license.zipCode}`;
                            if (this.license.zipCodePlus4 && this.license.zipCodePlus4 !== '0000' && !this.license.poBox) this.licenseFormatted += `-${this.license.zipCodePlus4}`;

                            if (this.license.user) this.fetchUser();
                        } else {
                            //not found
                        }
                    })
                    .catch(err => {
                        if (err.response?.status === 404) {
                            this.error.message = 'A license could not be found with the given callsign. If it was recently issued, it may not be available in our database yet. Please allow 24-48 hours after the Grant Date on your license before expecting to see your license here.';
                            this.error.state = true;
                        } else {
                            console.error(err.message);
                            this.error.message = 'An error occurred while fetching the license information. Please try again later.';
                            this.error.state = true;
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },

            fetchUser() {
                this.userLoading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/user/' + this.license.user, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.licenseUser = response.data;
                    })
                    .catch(err => {
                        console.error(JSON.stringify(err));
                    })
                    .then(() => {
                        this.userLoading = false;
                    });
            }
        },
        mounted() {
            this.fetchLicense();
        }
    };
</script>
